import React from "react";
import type { GetStaticPropsContext, NextPage } from "next";

import { IProjectList } from "shared/components-pages/proyectos/ProjectList";
import en from "lang/en.json";
import es from "lang/es.json";
import { CPagesPath } from "shared/models/const/pages-path";
import RealestateList from "shared/components-pages/proyectos/RealestateList";
import HeadMeta, { IHeadMetaComponent } from "shared/components/head-meta";
import { CKeyWordsPages } from "shared/models/const/keywords.const";
import { useRouter } from "next/dist/client/router";

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: locale === "es" ? es : en,
    },
  };
}

const IWantToBuy: NextPage = (props: any) => {
  const router = useRouter();
  const filters: IProjectList = {
    filters: {
      businessTypes: "sale",
    },
    refRequest: "property-list-sale",
    detailPath: CPagesPath.es.property_detail,
    ceoDynamic:false
  };

  const metaData: IHeadMetaComponent = {
    title: "iWantToShop",
    description: "iWantToBuy",
    keyWords: CKeyWordsPages.iWantToRent,
    url: router.asPath,
  };

  return (
    <>
      <HeadMeta {...metaData} />
      <RealestateList defaultFilters={filters} />
    </>
  );
};

export default IWantToBuy;
